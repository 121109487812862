import React from 'react'

// export default function ObserverHome() {
//     const observer = ({ value, didUpdate }) => {
//         useEffect(() => {
//           didUpdate(value)
//         }, [value])
//         return null // component does not render anything
//       }
//   return (
//     <div>ObserverHome</div>
//   )
// }

function ObserverHome() {
    const observer = ({ value, didUpdate }) => {
        useEffect(() => {
          didUpdate(value)
        }, [value])
        return null // component does not render anything
    }
    const mountHandler = ({ onMount, onUnMount }) => {
        useEffect(() => {
          onMount()
          return onUnMount
        },[])
        return null
    }
    console.log('testing')
  return (<div>ObserverHome</div>)
}

export default ObserverHome


